export default function Footer(){
    return <footer className="footer">
        <div className="footer-bottom">
          <div className="container-fluid">

            <div className="copyright">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="copyright-text">
                    <p className="mb-0">Copyright © 2010 - 2023 Centro Medico Genneruxi
                      <br />P.IVA 02101930929 - Direttore Sanitario Dr. Girolamo Campisi
                      <br />All right reserved.
                      <br />Developed by <a href="https://wwww.centro-assistenza-informatica.it"  title="centro assistenza informatica">Centro Assistenza Informatica</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="copyright-menu">
                    <ul className="policy-menu">
                      <li><a href="/" title="cookie policy">Cookie Policy</a>
                      </li>
                      <li><a href="/" title="privacy policy">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </footer>

}