import { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom"
import { ConfigContext } from "../../../Contexts/Config";
import Loader from "../../UI/Loader/loader";
import SearchAppointments from "../../UI/SearchAppointments/SearchAppointments";

import Moment from 'moment';

import parse from 'html-react-parser';
import ProfileSidebar from "../../ProfileSidebar/ProfileSidebar";
import { AuthContext } from "../../../Contexts/Auth";
 
export default function Appointments(){

    const {user} = useContext(AuthContext)

    const [appointments, setAppointments] = useState()

    let {api_urls} = useContext(ConfigContext)

    const token = JSON.parse(localStorage.getItem("user")).token

    const [selEmployee,setSelEmployee] = useState(user.doctor)

    let dFrom = (user.admin > 1) ? new Date() : new Date() //new Date('2022-07-01')

    let dTo = (user.admin > 1) ? new Date() : new Date('2023-12-31')

    let typeApp = (user.admin > 1) ? "1" : "0"

    let statusApp = (user.admin > 1) ? "1" : "0"

    const [dateFrom, setDateFrom] = useState(dFrom)

    const [dateTo, setDateTo] = useState(dTo)

    const [status, setStatus] = useState(statusApp)

    const [software, setSoftware] = useState(typeApp)

    let shouldLog = useRef(true)

    const [recordCount, setRecordCount] = useState("0")

    useEffect(() => {

        setAppointments()

        if (shouldLog.current){
           
          shouldLog.current = false

          let urlSearch = `appointments?status=${status}&from=${Moment(dateFrom).format('YYYY-MM-DD')}&to=${Moment(dateTo).format('YYYY-MM-DD')}&employee=${selEmployee}`

          if (software >= 0){
            urlSearch = urlSearch + '&software='+software
          }

          //console.log(urlSearch)

          fetch(
              `${api_urls.cmg}/${urlSearch}}`,{
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
              }
          )
          .then((response) => response.json())
          .then((appointments) => {

              //console.log(appointments)

              setRecordCount(appointments.total)

              //const sliceData = appointments.data.slice(0,10)

              setAppointments(appointments.data)
          })
        }
        
    },[status,dateFrom,dateTo,selEmployee,software])


    return <div className="content">
    <div className="container-fluid">
      <div className="row">
        {/* Profile Sidebar */}
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
          <ProfileSidebar/>
          {/* Search Appointmenmts */}
          <SearchAppointments 
            dateFrom={dateFrom} 
            setDateFrom={setDateFrom}
            dateTo={dateTo} 
            setDateTo={setDateTo}
            status = {status}
            setStatus = {setStatus}
            shouldLog = {shouldLog}
            software = {software}
            setSoftware = {setSoftware}
            selEmployee = {selEmployee}
            setSelEmployee ={setSelEmployee}
          />
          {/* Search Appointmenmts */}
        </div>
        {/* / Profile Sidebar */}
        <div className="col-md-7 col-lg-8 col-xl-9">
          <div className="card">
            <div className="card-body pt-0">
              {/* Tab Menu */}
              <nav className="user-tabs mb-4">
                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                  <li className="nav-item">
                    <a className="nav-link active" href="#pat_appointments" data-toggle="tab">
                      Appuntamenti {recordCount}
                    </a>
                  </li>
                </ul>
              </nav>
              {/* /Tab Menu */}
              {/* Tab Content */}
              <div className="tab-content pt-0">
                {/* Appointment Tab */}
                <div id="pat_appointments" className="tab-pane fade show active">
                  <div className="card card-table mb-0">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-hover table-center mb-0">
                          <thead>
                            <tr>
                              <th>Dottore</th>
                              <th>Paziente</th>
                              <th>Data</th>
                              <th>Stato</th>
                              <th /><th />
                            </tr>
                          </thead>
                          <tbody>
                            {
                            appointments &&
                            appointments.map((appointment) => {
                                return(<tr key={appointment.id}>
                                    <td>
                                        <span className="LabelGrid">Dottore</span>
                                        <h2 className="table-avatar text-capitalize">                                
                                        <Link to="/">
                                            Dr. {appointment.employee['first_name']+' '+appointment.employee['last_name']}
                                            
                                            {
                                                appointment.services &&  appointment.services.map (el => {
                                                    return <span key={el.id}>{el.name} </span>
                                                })
                                            } 
                                            
                                        </Link>
                                        </h2>
                                    </td>
                                    <td>
                                      <span className="LabelGrid">Paziente</span>
                                      { 
                                      appointment.customer_id > 1 ? 
                                        <span className="text-capitalize">{appointment.customer['first_name']+' '+appointment.customer['last_name']}</span>
                                        : 
                                        <span>{ parse(appointment.note) } </span>
                                      }
                                    </td>
                                    <td>
                                        <span className="LabelGrid">Data</span>
                                        {appointment.start_date.substring(0, 10)}
                                        <span className="d-block text-info">
                                            {appointment.appointment_time.substring(0, 5)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="LabelGrid">Stato</span>                                                  
                                        { 
                                          appointment.status === "1" ? 
                                          <span className="badge badge-pill bg-success-light">Confermato</span>
                                          : null
                                        }
                                        {  
                                          appointment.status === "2" ? 
                                          <span className="badge badge-pill bg-danger-light">Cancellato</span>
                                          :null 
                                        }  
                                        {
                                          appointment.status === "3" ? 
                                          <span className="badge badge-pill bg-danger-light">Cancellato</span>
                                          :null 
                                        }
                                        {    
                                          appointment.status === "4" ? 
                                          <span className="badge badge-pill bg-danger-light">Cancellato</span>
                                          :null 
                                        }
                                        {
                                          appointment.status === "0" ? 
                                          <span className="badge badge-pill bg-warning-light">In Attesa</span>
                                          :null 
                                        }
                                        
                                    </td>
                                    <td className="text-right">
                                        <span className="LabelGrid" />
                                        <div className="table-action">
                                        { 
                                        appointment.customer_id > 1 ?   
                                        <Link to={`/appointments/${appointment.id}`} className="btn btn-sm bg-info-light">
                                            <i className="far fa-eye" /> Apri
                                        </Link>
                                        :<></>
                                        }
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        <span className="LabelGrid" />
                                        <div className="table-action">
                                        </div>
                                    </td>
                                    </tr> )
                                })
                            }
                            { !appointments && <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <Loader/>
                                    <br/><br/><br/>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr> 
                            }  
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Appointment Tab */}
              </div>
              {/* Tab Content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
}