
import {createContext} from "react";

export const ConfigContext = createContext();

export function ConfigProvider(props){

    const demo = true;
    const local = false;

    let api_urls = {
        backend : process.env.REACT_APP_API_URL,
        cmg : process.env.REACT_APP_CMG_API_URL,
    }

    if (demo){
        api_urls = {
            backend : process.env.REACT_APP_API_URL_DEMO,
            cmg : process.env.REACT_APP_CMG_API_URL_DEMO,
        }
    }

    if (local){
        api_urls = {
            backend : process.env.REACT_APP_API_URL_LOCAL,
            cmg : process.env.REACT_APP_CMG_API_URL_LOCAL,
        }
    }

    const api_secrets = {
        //games : process.env.REACT_APP_GAMES_SECRET,
    }

    //console.log(api_urls)

    return(
        <ConfigContext.Provider value={{ api_urls, api_secrets }}>
            {props.children}
        </ConfigContext.Provider>    
    )
}