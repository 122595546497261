import React, { useContext, useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";

import Moment from 'moment';

import it from 'date-fns/locale/it'; //import italian locale

import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from '../../../Contexts/Auth';
import { ConfigContext } from '../../../Contexts/Config';

export default function SearchAppointments(
    {   dateFrom,setDateFrom,
        dateTo,setDateTo,
        status,setStatus,
        shouldLog,
        software,setSoftware,
        selEmployee,setSelEmployee,
    }){

    const {user} = useContext(AuthContext)   

    let {api_urls} = useContext(ConfigContext)

    const [employees,setEmployees] = useState(null)
    
    let shouldLogEmployees = useRef(true)

    // Employees - Doctors select
    useEffect(() => {

       setEmployees([])

        let employessSearch = (software === "1") ? "doctors" : "employees"
 
        if (shouldLogEmployees.current){
            shouldLogEmployees.current = false
  
          fetch(`${api_urls.cmg}/${employessSearch}`)
          .then(response => response.json())
          .then((data) => {
  
              //console.log(data.data)

              setEmployees(data.data)

          })
        }
        
    }, [software]);
    
    const setStartDate = (newDate) => {
        shouldLog.current = true
        setDateFrom(newDate)
    }

    const setFinishDate = (newDate) => {
        shouldLog.current = true
        setDateTo(newDate)
    }

    const setNewStatus = (value) => {
        shouldLog.current = true
        setStatus(value)
    }

    const setNewSoftware = (value) => {
        shouldLogEmployees.current = true
        shouldLog.current = true
        setSoftware(value)
    }

    const setNewSelEmployee = (value) => {
        shouldLog.current = true
        setSelEmployee(value)
    }

    return<div className="card search-filter">
            <div className="card-header">
                <h4 className="card-title mb-0">Filtri Ricerca</h4>
            </div>
            <div className="card-body">
            <div className={
                (user.admin > 1) ? "filter-widget react_disable" : "filter-widget" } 
            >
                    <h4>Tipo richiesta</h4>
                    <div className="form-group search-doctor">
                            <select 
                                onChange={(ev) => setNewSoftware(ev.target.value)}
                                value={software}
                                className="form-control"
                            >
                                <option value="0" key="1">WebApp</option>
                                <option value="1" key="2">Software</option>
                            </select>
                        </div>
                </div>
                <div className={
                    (user.admin > 1) ? "filter-widget react_disable" : "filter-widget" } 
                >
                    <h4>Medico</h4>
                    <div className="form-group search-doctor">
                        <select 
                            className="form-control"
                            onChange={(ev) => setNewSelEmployee(ev.target.value)}
                            value={selEmployee}
                        >
                            <option value="" key="0">-------</option>
                            {
                                employees &&  employees.map (el => {
                                    return <option 
                                                className="text-capitalize" 
                                                value={el.slug} 
                                                key={el.id}
                                            > 
                                                {el.last_name} {el.first_name}
                                            </option>
                                })
                            }        
                        </select>
                    </div>
                </div>
                <div className="filter-widget">
                    <h4>Dal</h4>
                    <div className="cal-icon">
                    <DatePicker
                        selected={dateFrom}
                        onChange={(newValue) => setStartDate(newValue)}
                        locale={it} // set italian locale
                        dateFormat="dd/MM/yyyy"
                        timeCaption="Time"
                        timeZone='Europe/Rome'
                        className="form-control datetimepicker"
                    />
                    </div>			
                </div>
                <div className="filter-widget">
                    <h4>Al</h4>
                    <div className="cal-icon">
                    <DatePicker
                        selected={dateTo}
                        onChange={(newValue) => setFinishDate(newValue)}
                        locale={it} // set italian locale
                        dateFormat="dd/MM/yyyy"
                        timeCaption="Time"
                        timeZone='Europe/Rome'
                        className="form-control datetimepicker"
                    />
                    </div>			
                </div>
                <div className={
                    (user.admin > 1) ? "filter-widget react_disable" : "filter-widget" } 
                >
                    <h4>Stato</h4>
                    <div className="form-group search-doctor">
                            <select 
                                onChange={(ev) => setNewStatus(ev.target.value)}
                                value={status}
                                className="form-control"
                            >
                                <option value="-1" key="0">Tutti</option>
                                <option value="0" key="1">In Attesa</option>
                                <option value="1" key="2">Confermato</option>
                                <option value="2" key="3">Cancellato</option>   
                            </select>
                        </div>
                </div>
            </div>
    </div>
        

}